import isEqual from 'lodash.isequal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { DataGridPro, GridFilterPanel, GridToolbarFilterButton, useGridApiRef } from '@mui/x-data-grid-pro';
import Button from '@truescope-web/react/lib/components/form/Button';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Sheet from '@truescope-web/react/lib/components/modal/SheetV2';
import SourceLogo from '@truescope-web/react/lib/components/widgets/SourceLogo';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { convertItems, renderPlaintextCell } from '../../../components/Dx/DxGridConstants';
import Header from '../../../components/Header';
import SelectFilter from '../../../components/Mui/DataGrid/SelectFilter';
import Source from './Source';

const CustomToolbar = () => (
	<div>
		<GridToolbarFilterButton />
	</div>
);

const CustomFilterPanel = (props) => {
	return <GridFilterPanel {...props} logicOperators={[]} />;
};

const Sources = () => {
	const [getDatahubApi] = useApiLookup();
	const [showSourceSheet, setShowSourceSheet] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selected, setSelected] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [create, setCreate] = useState(false);
	const [data, setData] = useState({ items: [], totalCount: 0, mediaTypes: [] });
	const [filterModel, setFilterModel] = useState({
		items: []
	});
	const [sortModel, setSortModel] = useState([{ field: 'name', sort: 'asc' }]);

	const previousFilterModel = useRef(filterModel);
	const hasDoneFirstSearch = useRef(false);
	const apiOptions = useRef({ offset: 0, limit: 20, include: ['media_types'] });
	const apiRef = useGridApiRef();

	const history = useHistory();

	const getSources = useCallback(
		async (filterModel, sortModel, isScrolling = false) => {
			if (isLoading) {
				return;
			}

			try {
				setIsLoading(true);
				const api = await getDatahubApi();

				// Include sorting and filtering in the API request
				const { data } = await api.post('/sources/v1', {
					...apiOptions.current,
					sortModel,
					filterModel
				});

				const mediaTypeIncludesOptionIndex = apiOptions.current.include.findIndex((i) => i === 'media_types');
				if (mediaTypeIncludesOptionIndex !== -1) {
					delete apiOptions.current.include[mediaTypeIncludesOptionIndex];
				}

				setData((prev) => {
					const isFiltering = !arrayIsNullOrEmpty(filterModel?.items);
					const state = {
						totalCount: data.totalCount,
						elasticQuery: data.elasticQuery,
						mediaTypes: !arrayIsNullOrEmpty(data.mediaTypes) ? data.mediaTypes : prev.mediaTypes,
						items:
							isFiltering && !isScrolling
								? convertItems(data.items, 'source_id')
								: [...prev.items, ...convertItems(data.items, 'source_id')]
					};
					return state;
				});
			} catch (e) {
				console.error('failed search', e);
			} finally {
				setIsLoading(false);
			}
		},
		[getDatahubApi, setIsLoading, isLoading]
	);

	useEffect(() => {
		if (hasDoneFirstSearch.current) {
			return;
		} else {
			hasDoneFirstSearch.current = true;
		}

		getSources(undefined, sortModel);
	}, [getSources, sortModel]);

	const handleRowsScrollEnd = useCallback(async () => {
		if (data.items.length === 0) {
			return;
		}

		apiOptions.current = {
			...apiOptions.current,
			offset: apiOptions.current.offset + 20
		};

		await getSources(filterModel, sortModel, true);
	}, [getSources, data.items.length, filterModel, sortModel]);

	const handleAddToSearch = () => {
		history.push({
			pathname: '/media/search',
			state: { queryText: `channel:"${selected.name}"` }
		});
	};

	const handleCloseMenu = (_e) => {
		setAnchorEl(null);
	};

	const handleOpenMenu = (e, selected) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
		setSelected(selected);
	};

	const handleEdit = () => {
		setShowSourceSheet(true);
		setAnchorEl(null);
	};

	const renderOptionsCell = (params) => {
		return (
			<div className="wrap">
				<IconButton aria-controls="row-menu" aria-haspopup="true" onClick={(e) => handleOpenMenu(e, params.row)}>
					<MoreVert />
				</IconButton>
			</div>
		);
	};

	const handleCloseSourceSheet = useCallback(() => {
		setShowSourceSheet(false);
		setSelected(null);
	}, [setShowSourceSheet, setSelected]);

	const renderSourceSheet = () => (
		<Sheet isOpen={showSourceSheet} onClose={handleCloseSourceSheet}>
			<div className="sheet-content">
				<Source source_id={selected?.source_id} />
			</div>
		</Sheet>
	);

	const renderCreateSheet = () => (
		<Sheet isOpen={create} onClose={() => setCreate(false)}>
			<Content>
				<Source mediaTypes={data.mediaTypes?.map?.((mt) => ({ media_type_id: mt.value, name: mt.text }))} createMode />
			</Content>
		</Sheet>
	);

	const renderSourceCell = (params) => {
		const overrideProfileImage = params.row.override_profile_image_url;
		return (
			<div className="wrap">
				<SourceLogo
					imageUrl={overrideProfileImage}
					sourceUrl={stringIsNullOrEmpty(overrideProfileImage) ? params.row.url : ''}
					sourceName={params.value}
				/>
			</div>
		);
	};

	const handleRowClick = useCallback(
		(params) => {
			setSelected(params.row);
			setShowSourceSheet(true);
		},
		[setSelected, setShowSourceSheet]
	);

	const mediaTypeFilterOperator = {
		label: 'equals',
		value: 'equals',
		requiresFilterValue: true,
		getApplyFilterFn: (filterItem) => {
			if (!filterItem.value || filterItem.value.length === 0) {
				return null;
			}
			return ({ value }) => filterItem.value.includes(value);
		},
		InputComponent: SelectFilter,
		InputComponentProps: {
			options: data.mediaTypes
		}
	};

	const handleSortModelChange = async (model) => {
		if (isLoading) {
			return;
		}
		setSortModel(model);
		await getSources(filterModel, model);
	};

	const renderGrid = () => {
		return (
			<div className="entities-sources__grid">
				<DataGridPro
					id="sources-table"
					apiRef={apiRef}
					slots={{
						toolbar: CustomToolbar,
						filterPanel: CustomFilterPanel
					}}
					rows={data.items}
					rowCount={data.totalCount || 0}
					filterModel={filterModel}
					sortModel={sortModel}
					hideFooter
					disableColumnSelector
					onRowsScrollEnd={handleRowsScrollEnd}
					onSortModelChange={handleSortModelChange}
					onFilterModelChange={(model) => setFilterModel(model)}
					columns={[
						{
							field: 'name',
							headerName: 'Channel',
							flex: 1,
							renderCell: renderSourceCell,
							sortable: true,
							filterable: true,
							pinnable: false,
							hideable: false
						},
						{
							field: 'media_type',
							headerName: 'Media Type',
							flex: 1,
							renderCell: renderPlaintextCell,
							sortable: true,
							filterable: true,
							pinnable: false,
							hideable: false,
							filterOperators: [mediaTypeFilterOperator]
						},
						{
							field: 'id',
							headerName: '',
							width: 60,
							resizable: false,
							disableColumnMenu: true,
							renderCell: renderOptionsCell,
							sortable: false,
							filterable: false
						}
					]}
					onRowClick={handleRowClick}
					loading={isLoading}
					disableSelectionOnClick
					sortingMode="server"
					filterMode="server"
				/>
			</div>
		);
	};

	const renderMenu = () => {
		return (
			<Menu id="row-menu" anchorEl={anchorEl} keepMounted open={!isNullOrUndefined(anchorEl)} onClose={handleCloseMenu}>
				<MenuItem onClick={handleAddToSearch}>
					<Typography>Add to Search</Typography>
				</MenuItem>
				<MenuItem onClick={handleEdit}>
					<Typography>Edit</Typography>
				</MenuItem>
			</Menu>
		);
	};

	useEffect(() => {
		if (isNullOrUndefined(apiRef.current)) {
			return;
		}

		const handlePreferencePanelClose = async () => {
			const { openedPanelValue } = apiRef.current.state.preferencePanel ?? {};
			if (openedPanelValue === 'filters') {
				if (
					isEqual(filterModel, previousFilterModel.current) ||
					filterModel.items.some((item) => isNullOrUndefined(item.value) || stringIsNullOrEmpty(item.value))
				) {
					return;
				}

				previousFilterModel.current = filterModel;

				apiOptions.current = {
					...apiOptions.current,
					limit: 20,
					offset: 0
				};

				setData((prev) => ({ items: [], totalCount: 0, mediaTypes: prev.mediaTypes }));

				await getSources(filterModel, sortModel);
			}
		};

		const unsubscribe = apiRef.current.subscribeEvent('preferencePanelClose', handlePreferencePanelClose);
		return () => {
			unsubscribe();
		};
	}, [apiRef, filterModel, sortModel, getSources]);

	return (
		<div className="entities-sources">
			<Content>
				<Header header="Channels">
					<Button fab={<Add />} variant="primary" onClick={() => setCreate(true)} aria-label="Add Channel" disabled={isLoading} />
				</Header>
				<Grid container>
					<Grid item>{renderGrid()}</Grid>
				</Grid>
			</Content>
			{renderSourceSheet()}
			{renderCreateSheet()}
			{renderMenu()}
		</div>
	);
};

export default Sources;
