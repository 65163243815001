import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

export const createApiKey = async (getDatahubApi, workspaceId) => {
	const api = await getDatahubApi();
	const { data } = await api.put(`/workspaces/v1/${workspaceId}/api-keys/`);

	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}

	return data.apiKey;
};

export const updateApiKey = async (getDatahubApi, workspaceId, updatedApiKey) => {
	const api = await getDatahubApi();
	const { data } = await api.patch(`/workspaces/v1/${workspaceId}/api-keys/${updatedApiKey.api_key_id}`, {
		is_deleted: updatedApiKey.is_deleted,
		include_body: updatedApiKey.include_body
	});

	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
};

export const deleteApiKey = async (getDatahubApi, workspaceId, apiKeyId) => {
	const api = await getDatahubApi();
	const { data } = await api.delete(`/workspaces/v1/${workspaceId}/api-keys/${apiKeyId}`);

	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
};
