import moment from 'moment';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

export const tweetCacheExpiryDays = 7;

/**
 * generates a key
 * @param {number} twitterRuleId
 * @returns {string}
 */
const getFeedRuleStorageKey = (twitterRuleId) => `rule_${twitterRuleId}`;

/**
 * caches twitter rule rulests based on the twitter rule id
 * @param {number} twitterRuleId
 * @param {number} estimatedMonthlyTotal
 * @param {object[]} tweets
 */
export const setCachedPreview = (twitterRuleId, estimatedMonthlyTotal, tweets) => {
	const key = getFeedRuleStorageKey(twitterRuleId);
	localStorage.setItem(key, JSON.stringify({ estimatedMonthlyTotal, tweets, timestamp: moment.utc().toISOString() }));
};

/**
 * clears the cached twitter rule
 * @param {number} twitterRuleId
 */
export const clearCachedPreview = (twitterRuleId) => {
	const key = getFeedRuleStorageKey(twitterRuleId);
	localStorage.removeItem(key);
};

/**
 * using a twitterRuleId, gets the cached twitter rule results
 * @param {number} twitterRuleId
 * @returns results
 */
export const getCachedPreview = (twitterRuleId) => {
	const key = getFeedRuleStorageKey(twitterRuleId);
	const cachedPreviewString = localStorage.getItem(key);
	if (stringIsNullOrEmpty(cachedPreviewString)) {
		return null;
	}
	try {
		const { estimatedMonthlyTotal, tweets, timestamp } = JSON.parse(cachedPreviewString);
		const previewExpiryDate = moment(timestamp).add(tweetCacheExpiryDays, 'days');
		if (moment().utc().isBefore(previewExpiryDate)) {
			return { estimatedMonthlyTotal, tweets, timestamp };
		}
		//this has expired, remove it
		clearCachedPreview(twitterRuleId);
	} catch (e) {
		console.error(`failed to restored cached preview - ${e.message}`, { key, cachedPreviewString });
	}
	return null;
};
