import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Select from '@truescope-web/react/lib/components/form/Select';
import Switch from '@truescope-web/react/lib/components/form/Switch';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Alert from '@truescope-web/react/lib/components/modal/Alert';
import { workspaceUserStatesLookup } from '@truescope-web/utils/lib/workspaces';
import { useConfig } from '../../../components/ConfigProvider';

const Row = ({ workspaceUser, onChange }) => {
	const [showWarning, setShowWarning] = useState(false);
	const { config } = useConfig();

	const handleWorkspaceUserRoleChange = (_e, value) => {
		onChange({
			...workspaceUser,
			role_id: value
		});
	};

	const handleWorkspaceUserStateToggle = () => {
		if (workspaceUser.workspace_user_state_id === workspaceUserStatesLookup.active) {
			setShowWarning(true);
			return;
		}

		onChange({
			...workspaceUser,
			workspace_user_state_id: workspaceUserStatesLookup.active
		});
	};

	const handleAcceptDeactivateWorkspaceUser = () => {
		setShowWarning(false);
		onChange({
			...workspaceUser,
			workspace_user_state_id: workspaceUserStatesLookup.inactive
		});
	};

	return (
		<>
			<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				<TableCell>{workspaceUser.name}</TableCell>
				<TableCell>
					<Select
						value={workspaceUser.role_id}
						options={config.roleOptions}
						onChange={handleWorkspaceUserRoleChange}
						menuPosition="fixed"
					/>
				</TableCell>
				<TableCell>
					<Switch
						value={workspaceUser.workspace_user_state_id === workspaceUserStatesLookup.active}
						onChange={handleWorkspaceUserStateToggle}
						color="primary"
					/>
				</TableCell>
			</TableRow>
			<Alert
				title="Warning"
				renderContent={() => (
					<div>
						<Typography variant="subtitle">
							Deactivated user will lose all of their Dashboard, Report and Alert permissions.
						</Typography>
						<Typography variant="subtitle">Would you like to deactivate this user?</Typography>
					</div>
				)}
				cancelLabel="No"
				acceptLabel="Yes"
				open={showWarning}
				handleCancel={() => setShowWarning(false)}
				handleAccept={handleAcceptDeactivateWorkspaceUser}
			/>
		</>
	);
};

export default Row;
