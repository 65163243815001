import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

export const countryOptions = [
	{ label: 'Afghanistan', value: 'Afghanistan' },
	{ label: 'Albania', value: 'Albania' },
	{ label: 'Antarctica', value: 'Antarctica' },
	{ label: 'Algeria', value: 'Algeria' },
	{ label: 'American Samoa', value: 'American Samoa' },
	{ label: 'Andorra', value: 'Andorra' },
	{ label: 'Angola', value: 'Angola' },
	{ label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
	{ label: 'Azerbaijan', value: 'Azerbaijan' },
	{ label: 'Argentina', value: 'Argentina' },
	{ label: 'Australia', value: 'Australia' },
	{ label: 'Austria', value: 'Austria' },
	{ label: 'The Bahamas', value: 'The Bahamas' },
	{ label: 'Bahrain', value: 'Bahrain' },
	{ label: 'Bangladesh', value: 'Bangladesh' },
	{ label: 'Armenia', value: 'Armenia' },
	{ label: 'Barbados', value: 'Barbados' },
	{ label: 'Belgium', value: 'Belgium' },
	{ label: 'Bermuda', value: 'Bermuda' },
	{ label: 'Bhutan', value: 'Bhutan' },
	{ label: 'Bolivia', value: 'Bolivia' },
	{ label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
	{ label: 'Botswana', value: 'Botswana' },
	{ label: 'Bouvet Island', value: 'Bouvet Island' },
	{ label: 'Brazil', value: 'Brazil' },
	{ label: 'Belize', value: 'Belize' },
	{ label: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory' },
	{ label: 'Solomon Islands', value: 'Solomon Islands' },
	{ label: 'British Virgin Islands', value: 'British Virgin Islands' },
	{ label: 'Brunei', value: 'Brunei' },
	{ label: 'Bulgaria', value: 'Bulgaria' },
	{ label: 'Myanmar (Burma)', value: 'Myanmar (Burma)' },
	{ label: 'Burundi', value: 'Burundi' },
	{ label: 'Cambodia', value: 'Cambodia' },
	{ label: 'Cameroon', value: 'Cameroon' },
	{ label: 'Canada', value: 'Canada' },
	{ label: 'Cabo Verde', value: 'Cabo Verde' },
	{ label: 'Cayman Islands', value: 'Cayman Islands' },
	{ label: 'Central African Republic', value: 'Central African Republic' },
	{ label: 'Sri Lanka', value: 'Sri Lanka' },
	{ label: 'Chad', value: 'Chad' },
	{ label: 'Chile', value: 'Chile' },
	{ label: 'China', value: 'China' },
	{ label: 'Taiwan', value: 'Taiwan' },
	{ label: 'Christmas Island', value: 'Christmas Island' },
	{ label: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands' },
	{ label: 'Colombia', value: 'Colombia' },
	{ label: 'Comoros', value: 'Comoros' },
	{ label: 'Mayotte', value: 'Mayotte' },
	{ label: 'Republic of the Congo', value: 'Republic of the Congo' },
	{ label: 'Democratic Republic of the Congo', value: 'Democratic Republic of the Congo' },
	{ label: 'Cook Islands', value: 'Cook Islands' },
	{ label: 'Costa Rica', value: 'Costa Rica' },
	{ label: 'Croatia', value: 'Croatia' },
	{ label: 'Cyprus', value: 'Cyprus' },
	{ label: 'Czechia', value: 'Czechia' },
	{ label: 'Benin', value: 'Benin' },
	{ label: 'Denmark', value: 'Denmark' },
	{ label: 'Dominica', value: 'Dominica' },
	{ label: 'Dominican Republic', value: 'Dominican Republic' },
	{ label: 'Ecuador', value: 'Ecuador' },
	{ label: 'El Salvador', value: 'El Salvador' },
	{ label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
	{ label: 'Ethiopia', value: 'Ethiopia' },
	{ label: 'Eritrea', value: 'Eritrea' },
	{ label: 'Estonia', value: 'Estonia' },
	{ label: 'Faroe Islands', value: 'Faroe Islands' },
	{ label: 'Falkland Islands (Islas Malvinas)', value: 'Falkland Islands (Islas Malvinas)' },
	{ label: 'South Georgia and the South Sandwich Islands', value: 'South Georgia and the South Sandwich Islands' },
	{ label: 'Fiji', value: 'Fiji' },
	{ label: 'Finland', value: 'Finland' },
	{ label: 'France', value: 'France' },
	{ label: 'French Guiana', value: 'French Guiana' },
	{ label: 'French Polynesia', value: 'French Polynesia' },
	{ label: 'French Southern and Antarctic Lands', value: 'French Southern and Antarctic Lands' },
	{ label: 'Djibouti', value: 'Djibouti' },
	{ label: 'Gabon', value: 'Gabon' },
	{ label: 'Georgia', value: 'Georgia' },
	{ label: 'The Gambia', value: 'The Gambia' },
	{ label: 'Palestine', value: 'Palestine' },
	{ label: 'Germany', value: 'Germany' },
	{ label: 'Ghana', value: 'Ghana' },
	{ label: 'Gibraltar', value: 'Gibraltar' },
	{ label: 'Kiribati', value: 'Kiribati' },
	{ label: 'Greece', value: 'Greece' },
	{ label: 'Greenland', value: 'Greenland' },
	{ label: 'Grenada', value: 'Grenada' },
	{ label: 'Guadeloupe', value: 'Guadeloupe' },
	{ label: 'Guam', value: 'Guam' },
	{ label: 'Guatemala', value: 'Guatemala' },
	{ label: 'Guinea', value: 'Guinea' },
	{ label: 'Guyana', value: 'Guyana' },
	{ label: 'Haiti', value: 'Haiti' },
	{ label: 'Heard Island and McDonald Islands', value: 'Heard Island and McDonald Islands' },
	{ label: 'Vatican City', value: 'Vatican City' },
	{ label: 'Honduras', value: 'Honduras' },
	{ label: 'Hong Kong', value: 'Hong Kong' },
	{ label: 'Hungary', value: 'Hungary' },
	{ label: 'Iceland', value: 'Iceland' },
	{ label: 'India', value: 'India' },
	{ label: 'Indonesia', value: 'Indonesia' },
	{ label: 'Iraq', value: 'Iraq' },
	{ label: 'Ireland', value: 'Ireland' },
	{ label: 'Israel', value: 'Israel' },
	{ label: 'Italy', value: 'Italy' },
	{ label: "Cote d'Ivoire", value: "Cote d'Ivoire" },
	{ label: 'Jamaica', value: 'Jamaica' },
	{ label: 'Japan', value: 'Japan' },
	{ label: 'Kazakhstan', value: 'Kazakhstan' },
	{ label: 'Jordan', value: 'Jordan' },
	{ label: 'Kenya', value: 'Kenya' },
	{ label: 'South Korea', value: 'South Korea' },
	{ label: 'Kuwait', value: 'Kuwait' },
	{ label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
	{ label: 'Laos', value: 'Laos' },
	{ label: 'Lebanon', value: 'Lebanon' },
	{ label: 'Lesotho', value: 'Lesotho' },
	{ label: 'Latvia', value: 'Latvia' },
	{ label: 'Liberia', value: 'Liberia' },
	{ label: 'Libya', value: 'Libya' },
	{ label: 'Liechtenstein', value: 'Liechtenstein' },
	{ label: 'Lithuania', value: 'Lithuania' },
	{ label: 'Luxembourg', value: 'Luxembourg' },
	{ label: 'Macao', value: 'Macao' },
	{ label: 'Madagascar', value: 'Madagascar' },
	{ label: 'Malawi', value: 'Malawi' },
	{ label: 'Malaysia', value: 'Malaysia' },
	{ label: 'Maldives', value: 'Maldives' },
	{ label: 'Mali', value: 'Mali' },
	{ label: 'Malta', value: 'Malta' },
	{ label: 'Martinique', value: 'Martinique' },
	{ label: 'Mauritania', value: 'Mauritania' },
	{ label: 'Mauritius', value: 'Mauritius' },
	{ label: 'Mexico', value: 'Mexico' },
	{ label: 'Monaco', value: 'Monaco' },
	{ label: 'Mongolia', value: 'Mongolia' },
	{ label: 'Moldova', value: 'Moldova' },
	{ label: 'Montenegro', value: 'Montenegro' },
	{ label: 'Montserrat', value: 'Montserrat' },
	{ label: 'Morocco', value: 'Morocco' },
	{ label: 'Mozambique', value: 'Mozambique' },
	{ label: 'Oman', value: 'Oman' },
	{ label: 'Namibia', value: 'Namibia' },
	{ label: 'Nauru', value: 'Nauru' },
	{ label: 'Nepal', value: 'Nepal' },
	{ label: 'Netherlands', value: 'Netherlands' },
	{ label: 'Curacao', value: 'Curacao' },
	{ label: 'Aruba', value: 'Aruba' },
	{ label: 'Sint Maarten', value: 'Sint Maarten' },
	{ label: 'Caribbean Netherlands', value: 'Caribbean Netherlands' },
	{ label: 'New Caledonia', value: 'New Caledonia' },
	{ label: 'Vanuatu', value: 'Vanuatu' },
	{ label: 'New Zealand', value: 'New Zealand' },
	{ label: 'Nicaragua', value: 'Nicaragua' },
	{ label: 'Niger', value: 'Niger' },
	{ label: 'Nigeria', value: 'Nigeria' },
	{ label: 'Niue', value: 'Niue' },
	{ label: 'Norfolk Island', value: 'Norfolk Island' },
	{ label: 'Norway', value: 'Norway' },
	{ label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
	{ label: 'United States Minor Outlying Islands', value: 'United States Minor Outlying Islands' },
	{ label: 'Micronesia', value: 'Micronesia' },
	{ label: 'Marshall Islands', value: 'Marshall Islands' },
	{ label: 'Palau', value: 'Palau' },
	{ label: 'Pakistan', value: 'Pakistan' },
	{ label: 'Panama', value: 'Panama' },
	{ label: 'Papua New Guinea', value: 'Papua New Guinea' },
	{ label: 'Paraguay', value: 'Paraguay' },
	{ label: 'Peru', value: 'Peru' },
	{ label: 'Philippines', value: 'Philippines' },
	{ label: 'Pitcairn Islands', value: 'Pitcairn Islands' },
	{ label: 'Poland', value: 'Poland' },
	{ label: 'Portugal', value: 'Portugal' },
	{ label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
	{ label: 'Timor-Leste', value: 'Timor-Leste' },
	{ label: 'Puerto Rico', value: 'Puerto Rico' },
	{ label: 'Qatar', value: 'Qatar' },
	{ label: 'Reunion', value: 'Reunion' },
	{ label: 'Romania', value: 'Romania' },
	{ label: 'Rwanda', value: 'Rwanda' },
	{ label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'Saint Helena, Ascension and Tristan da Cunha' },
	{ label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
	{ label: 'Anguilla', value: 'Anguilla' },
	{ label: 'Saint Lucia', value: 'Saint Lucia' },
	{ label: 'Saint Pierre and Miquelon', value: 'Saint Pierre and Miquelon' },
	{ label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
	{ label: 'San Marino', value: 'San Marino' },
	{ label: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
	{ label: 'Saudi Arabia', value: 'Saudi Arabia' },
	{ label: 'Senegal', value: 'Senegal' },
	{ label: 'Serbia', value: 'Serbia' },
	{ label: 'Seychelles', value: 'Seychelles' },
	{ label: 'Sierra Leone', value: 'Sierra Leone' },
	{ label: 'Singapore', value: 'Singapore' },
	{ label: 'Slovakia', value: 'Slovakia' },
	{ label: 'Vietnam', value: 'Vietnam' },
	{ label: 'Slovenia', value: 'Slovenia' },
	{ label: 'Somalia', value: 'Somalia' },
	{ label: 'South Africa', value: 'South Africa' },
	{ label: 'Zimbabwe', value: 'Zimbabwe' },
	{ label: 'Spain', value: 'Spain' },
	{ label: 'Western Sahara', value: 'Western Sahara' },
	{ label: 'Suriname', value: 'Suriname' },
	{ label: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen' },
	{ label: 'Eswatini', value: 'Eswatini' },
	{ label: 'Sweden', value: 'Sweden' },
	{ label: 'Switzerland', value: 'Switzerland' },
	{ label: 'Tajikistan', value: 'Tajikistan' },
	{ label: 'Thailand', value: 'Thailand' },
	{ label: 'Togo', value: 'Togo' },
	{ label: 'Tokelau', value: 'Tokelau' },
	{ label: 'Tonga', value: 'Tonga' },
	{ label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
	{ label: 'United Arab Emirates', value: 'United Arab Emirates' },
	{ label: 'Tunisia', value: 'Tunisia' },
	{ label: 'Turkiye', value: 'Turkiye' },
	{ label: 'Turkmenistan', value: 'Turkmenistan' },
	{ label: 'Turks and Caicos Islands', value: 'Turks and Caicos Islands' },
	{ label: 'Tuvalu', value: 'Tuvalu' },
	{ label: 'Uganda', value: 'Uganda' },
	{ label: 'Ukraine', value: 'Ukraine' },
	{ label: 'North Macedonia', value: 'North Macedonia' },
	{ label: 'Egypt', value: 'Egypt' },
	{ label: 'United Kingdom', value: 'United Kingdom' },
	{ label: 'Guernsey', value: 'Guernsey' },
	{ label: 'Jersey', value: 'Jersey' },
	{ label: 'Isle of Man', value: 'Isle of Man' },
	{ label: 'Tanzania', value: 'Tanzania' },
	{ label: 'United States', value: 'United States' },
	{ label: 'U.S. Virgin Islands', value: 'U.S. Virgin Islands' },
	{ label: 'Burkina Faso', value: 'Burkina Faso' },
	{ label: 'Uruguay', value: 'Uruguay' },
	{ label: 'Uzbekistan', value: 'Uzbekistan' },
	{ label: 'Venezuela', value: 'Venezuela' },
	{ label: 'Wallis and Futuna', value: 'Wallis and Futuna' },
	{ label: 'Samoa', value: 'Samoa' },
	{ label: 'Yemen', value: 'Yemen' },
	{ label: 'Zambia', value: 'Zambia' }
];

export const socialNetworkOptions = [
	{
		label: 'All',
		value: 'All',
		metadata: '(site:facebook.com OR site:instagram.com OR site:linkedin.com OR site:tiktok.com OR site:youtube.com)'
	},
	{ label: 'Facebook', value: 'Facebook', metadata: 'site:facebook.com' },
	{ label: 'Instagram', value: 'Instagram', metadata: 'site:instagram.com' },
	{ label: 'LinkedIn', value: 'LinkedIn', metadata: 'site:linkedin.com' },
	{ label: 'Tiktok', value: 'Tiktok', metadata: 'site:tiktok.com' },
	{ label: 'Youtube', value: 'Youtube', metadata: 'site:youtube.com' }
].sort();

/**
 * variants of a social handle
 */
export const variants = {
	disabled: 'disabled',
	registered: 'registered',
	unregistered: 'unregistered'
};

/**
 * gets the variant of the social handle
 * @param {object} socialHandle
 * @returns {string} variant
 */
export const getVariant = (socialHandle) => {
	if (socialHandle['social-network'].toLowerCase() === 'twitter') {
		return variants.disabled;
	}
	return socialHandle.is_registered ? variants.registered : variants.unregistered;
};

/**
 * given the location object, this extracts the parameters and returns an object that can be used to prime the social handles view
 * @param {Object} urlLocation
 * @returns { keyword, location, socialNetwork } keyword/location/socialNetwork object
 */
export const getUrlParams = (urlLocation) => {
	const searchParams = new URLSearchParams(urlLocation.search);
	const locationString = searchParams.get('location')?.toLowerCase();
	const socialNetworkString = (searchParams.get('socialNetwork') || searchParams.get('socialnetwork'))?.toLowerCase();
	const keyword = searchParams.get('term');

	return stringIsNullOrEmpty(keyword) && stringIsNullOrEmpty(socialNetworkString) && stringIsNullOrEmpty(locationString)
		? undefined
		: {
				keyword,
				location: !stringIsNullOrEmpty(locationString)
					? countryOptions.find((option) => option.label.toLowerCase() === locationString)
					: getDefaultLocation(),
				socialNetwork: !arrayIsNullOrEmpty(socialNetworkString)
					? socialNetworkOptions.find((option) => option.label.toLowerCase() === socialNetworkString)
					: getDefaultSocialNetworkOption()
		  };
};

export const getDefaultLocation = () => countryOptions.find((option) => option.value === 'United States');

export const getDefaultSocialNetworkOption = () => socialNetworkOptions.find((option) => option.value === 'All');
