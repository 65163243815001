import { cleanString, encodeBase64, stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

export const getFeedRule = (getDatahubApi, feedRuleId) => {
	return getDatahubApi().then((api) =>
		api.get(`/feeds/v1/feed_rules/${feedRuleId}`).then(({ data }) => {
			return {
				feed_rule: data.feed_rule
			};
		})
	);
};

export const updateFeedRule = (getDatahubApi, { feed_rule_id, name, rule, is_enabled }) => {
	return getDatahubApi().then((api) =>
		api
			.patch('/feeds/v1/feed_rules', {
				feedRule: { feed_rule_id, name, rule: cleanString(rule), is_enabled }
			})
			.then(({ data }) => data)
	);
};

export const createFeedRule = (getDatahubApi, { name, rule, is_enabled, feed_id }) => {
	return getDatahubApi().then((api) =>
		api
			.put('/feeds/v1/feed_rules', {
				feedRule: { name, rule: cleanString(rule), is_enabled, feed_id }
			})
			.then(({ data }) => data)
	);
};

export const getRulePreview = (getDatahubApi, query) => {
	return getDatahubApi().then((api) =>
		api
			.post('/feeds/v1/feed_rules/preview', {
				query: cleanString(query),
				cacheKey: stringIsNullOrEmpty(query) ? undefined : encodeBase64(query)
			})
			.then(({ data }) => data)
	);
};
