import cloneDeep from 'lodash.clonedeep';
import React, { useEffect, useMemo, useState } from 'react';
import { LinearProgress } from '@mui/material';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { htmlDateToIsoString, isoStringToHtmlFormat } from '@truescope-web/react/lib/components/widgets/DatePicker';
import { validateObject } from '@truescope-web/react/lib/utils/validation';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError } from '../../../../components/Api';
import { useApiLookup } from '../../../../components/ApiLookupProvider';
import { useConfig } from '../../../../components/ConfigProvider';
import Header from '../../../../components/Header';
import ApiKeys from './ApiKeys/ApiKeys';
import ContentRights from './ContentRights/ContentRights';
import Details from './Details/Details';
import LayoutSeparator from './LayoutSeparator';
import Market from './Market/WorkspaceMarket';
import Options from './Options/Options';
import Plan from './Plan/Plan';
import Scopes from './Scopes/Scopes';
import Users from './Users/Users';
import { getWorkspace } from './api';
import { updateWorkspace } from './api';
import { workspaceValidationRules } from './constants';

const Workspace = ({ match, workspace_id: wid }) => {
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();
	const workspaceId = useMemo(() => (!stringIsNullOrEmpty(wid) ? wid : match.params.workspace_id), [wid, match.params.workspace_id]);
	const [workspaceContentRights, setWorkspaceContentRights] = useState([]);
	const [workspace, setWorkspace] = useState(null);
	const [workspaceClient, setWorkspaceClient] = useState(null);
	const [apiKeys, setApiKeys] = useState([]);
	const [workspaceScopes, setWorkspaceScopes] = useState([]);
	const [workspacePlan, setWorkspacePlan] = useState(null);
	const [workspaceUsers, setWorkspaceUsers] = useState([]);
	const [workspaceInvites, setWorkspaceInvites] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isPartialLoading, setIsPartialLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const [validationState, setValidationState] = useState(null);
	const { config } = useConfig();
	const workspaceClientMarket = config.marketsLookup[workspaceClient?.market_id];

	useEffect(() => {
		setIsLoading(true);
		getWorkspace(getDatahubApi, workspaceId)
			.then(({ users, invites, workspace, apiKeys, scopes, plan, contentRights, client }) => {
				setWorkspacePlan(plan);
				setWorkspaceClient(client);
				setWorkspaceScopes(scopes);
				setWorkspace(workspace);
				setWorkspaceUsers(users);
				setWorkspaceInvites(invites);
				setWorkspaceContentRights(contentRights);
				setApiKeys(apiKeys);
			})
			.catch((error) => setMessage(error.message))
			.finally(() => setIsLoading(false));
	}, [getDatahubApi, workspaceId]);

	const saveWorkspace = async (updatedWorkspace) => {
		setIsPartialLoading(true);
		const previousWorkspace = cloneDeep(workspace);
		try {
			setWorkspace(updatedWorkspace);
			await updateWorkspace(getDatahubApi, updatedWorkspace);
		} catch (e) {
			const msg = `failed to save workspace - ${extractError(e)}`;
			console.error(msg);
			showSnackbar(msg, snackbarVariants.error);
			setWorkspace(previousWorkspace);
		} finally {
			setIsPartialLoading(false);
		}
	};

	const handlePlanEndDateChange = (e) => {
		const updatedWorkspace = {
			...workspace,
			plan_end_date: htmlDateToIsoString(e.target.value)
		};

		const customDateValidation = validateObject(updatedWorkspace, workspaceValidationRules);
		setValidationState(customDateValidation);
		if (!customDateValidation.success) {
			return;
		}
		saveWorkspace(updatedWorkspace);
	};

	const renderWorkspace = () => {
		return (
			<>
				<Grid container spacing={1}>
					<Grid item>
						<Market clientMarket={workspaceClientMarket} />
						<div className="partial-loading">
							{isPartialLoading && <LinearProgress className="partial-loading__linear-progress" variant="indeterminate" />}
						</div>
					</Grid>
					<Grid item>
						<LayoutSeparator />
					</Grid>
					<Grid item>
						<Details workspace={workspace} />
					</Grid>
					<Grid item>
						<LayoutSeparator />
					</Grid>
					<Grid item>
						<Plan
							plan={workspacePlan}
							workspace={workspace}
							onPlanEndDateChange={handlePlanEndDateChange}
							validation={validationState}
							dateTo={isoStringToHtmlFormat(workspace?.plan_end_date)}
						/>
					</Grid>
					<Grid item>
						<LayoutSeparator />
					</Grid>
					<Grid item>
						<Options workspace={workspace} disabled={isPartialLoading || isLoading} onSaveWorkspace={saveWorkspace} />
					</Grid>
					<Grid item>
						<LayoutSeparator />
					</Grid>
					{config.isAdmin && (
						<Grid item>
							<ApiKeys
								workspaceId={workspace.workspace_id}
								apiKeys={apiKeys}
								setApiKeys={setApiKeys}
								isPartialLoading={isPartialLoading}
								setIsPartialLoading={setIsPartialLoading}
							/>
						</Grid>
					)}
					<Grid item>
						<LayoutSeparator />
					</Grid>
					<Grid item>
						<Grid container spacing={6}>
							<Grid item>
								<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
									<Typography variant="h4">Content</Typography>
								</Inline>
							</Grid>
							<Grid item lg={6}>
								<Scopes workspace={workspace} scopes={workspaceScopes} setScopes={setWorkspaceScopes} />
							</Grid>
							<Grid item lg={6}>
								<ContentRights workspaceId={workspaceId} workspaceContentRights={workspaceContentRights} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<LayoutSeparator />
					</Grid>
					<Grid item>
						<Users
							workspaceInvites={workspaceInvites}
							setWorkspaceInvites={setWorkspaceInvites}
							workspaceUsers={workspaceUsers}
							setWorkspaceUsers={setWorkspaceUsers}
							workspacePlan={workspacePlan}
							workspaceId={workspaceId}
						/>
					</Grid>
				</Grid>
			</>
		);
	};

	const renderContent = () => {
		if (isLoading) {
			return (
				<Grid item>
					<SkeletonWrapper />
				</Grid>
			);
		}

		if (!isNullOrUndefined(message)) {
			return (
				<Grid container>
					<Grid item>{message}</Grid>
				</Grid>
			);
		}

		if (isNullOrUndefined(workspace)) {
			<Grid container>
				<Grid item>No workspace loaded</Grid>
			</Grid>;
		}

		return renderWorkspace();
	};

	return (
		<Content>
			<Header header={isLoading ? '...' : workspace.name || ''} />
			{renderContent()}
		</Content>
	);
};

export default Workspace;
