import React from 'react';
import Add from '@mui/icons-material/Add';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { extractError } from '../../../../../components/Api';
import { useApiLookup } from '../../../../../components/ApiLookupProvider';
import Row from './Row';
import { createApiKey, deleteApiKey, updateApiKey } from './api';

const ApiKeys = ({ workspaceId, apiKeys, setApiKeys, isPartialLoading, setIsPartialLoading }) => {
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();

	const handleAddApiKeyClick = async () => {
		try {
			setIsPartialLoading(true);
			const apiKey = await createApiKey(getDatahubApi, workspaceId);
			setApiKeys((prev) => [...prev, apiKey]);
		} catch (e) {
			const msg = `failed to create api key - ${extractError(e)}`;
			console.error(msg, e);
			showSnackbar(msg, snackbarVariants.error);
		} finally {
			setIsPartialLoading(false);
		}
	};

	const handleApiKeyChange = async (updatedApiKey, index) => {
		const previousApiKeys = [...apiKeys];
		try {
			setIsPartialLoading(true);
			setApiKeys((prev) => {
				const updatedApiKeys = [...prev];
				updatedApiKeys[index] = updatedApiKey;
				return updatedApiKeys;
			});
			await updateApiKey(getDatahubApi, workspaceId, updatedApiKey);
		} catch (e) {
			const msg = `failed to update api key - ${extractError(e)}`;
			console.error(msg, e);
			showSnackbar(msg, snackbarVariants.error);
			setApiKeys(previousApiKeys);
		} finally {
			setIsPartialLoading(false);
		}
	};

	const handleDeleteClick = async (index) => {
		const previousApiKeys = [...apiKeys];
		try {
			setIsPartialLoading(true);
			const apiKey = previousApiKeys[index];
			setApiKeys((prev) => {
				const updatedApiKeys = [...prev];
				updatedApiKeys.splice(index, 1);
				return updatedApiKeys;
			});
			await deleteApiKey(getDatahubApi, workspaceId, apiKey.api_key_id);
		} catch (e) {
			const msg = `failed to delete api key - ${extractError(e)}`;
			console.error(msg, e);
			showSnackbar(msg, snackbarVariants.error);
			setApiKeys(previousApiKeys);
		} finally {
			setIsPartialLoading(false);
		}
	};

	return (
		<Grid container>
			<Grid item>
				<Inline>
					<Typography variant="h4">Api Keys</Typography>
					<ToolbarButton title="Add Api Key" onClick={handleAddApiKeyClick} icon={<Add />} loading={isPartialLoading} />
				</Inline>
			</Grid>
			<Grid item>
				{arrayIsNullOrEmpty(apiKeys) ? (
					<Typography variant="subtitle">
						This workspace does not contain any api keys.{' '}
						<Link className="link" onClick={handleAddApiKeyClick}>
							Click Here
						</Link>{' '}
						to add the first one.
					</Typography>
				) : (
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell>Key</TableCell>
									<TableCell>Date Created</TableCell>
									<TableCell>Include Body</TableCell>
									<TableCell>Disable</TableCell>
									<TableCell>Delete</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{apiKeys.map((apiKey, index) => (
									<Row
										key={apiKey.api_key_id}
										apiKey={apiKey}
										onChange={(updatedApiKey) => handleApiKeyChange(updatedApiKey, index)}
										onDelete={() => handleDeleteClick(index)}
										disabled={isPartialLoading}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Grid>
		</Grid>
	);
};

export default ApiKeys;
