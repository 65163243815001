import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Paper from '@truescope-web/react/lib/components/layout/Paper';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../../components/ApiLookupProvider';
import Header from '../../../../components/Header';
import { feedNamesLookup } from '../constants';
import PreviewDataGrid from './PreviewDataGrid';
import ToolbarButtons from './ToolbarButtons';
import { getFeedRule, getRulePreview } from './api';
import { clearCachedPreview, getCachedPreview, setCachedPreview, tweetCacheExpiryDays } from './constants';

const Rule = ({ create, feedId }) => {
	const match = useRouteMatch();
	const { feed_rule_id: feedRuleId } = match.params;
	const { showSnackbar } = useSnackbar();
	const [getDatahubApi] = useApiLookup();
	const [canSave, setCanSave] = useState(false);
	const [isPartialLoading, setIsPartialLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [feedRule, setFeedRule] = useState(null);
	const [results, setResults] = useState(null);

	useEffect(() => {
		setResults(getCachedPreview(feedRuleId));
	}, [feedRuleId]);

	const loadFeedRule = useCallback(async () => {
		setIsLoading(true);
		setErrorMessage(null);
		setFeedRule(null);
		try {
			if (create) {
				setFeedRule({
					feed_id: feedId,
					name: '',
					rule: '',
					preview_item_count: 20,
					is_enabled: true
				});
			} else {
				const { feed_rule: loadedFeedRule } = await getFeedRule(getDatahubApi, feedRuleId);
				setFeedRule(loadedFeedRule);
			}
		} catch (e) {
			const msg = `failed to load feed - ${e.message}`;
			console.error(msg, e);
			setErrorMessage(msg);
		} finally {
			setIsLoading(false);
		}
	}, [create, getDatahubApi, setFeedRule, setIsLoading, feedRuleId]);

	useEffect(() => {
		loadFeedRule();
	}, [loadFeedRule]);

	const loadPreviewData = useCallback(async () => {
		if (!(create || feedRule?.is_enabled)) {
			setCanSave(true);
			return;
		}

		setIsPartialLoading(true);
		try {
			if (stringIsNullOrEmpty(feedRule?.rule)) {
				throw new Error(`feed rule query is empty`);
			}
			const { tweets, estimatedMonthlyTotal, errorMessage } = await getRulePreview(getDatahubApi, feedRule.rule);
			if (!stringIsNullOrEmpty(errorMessage)) {
				throw new Error(errorMessage);
			}
			setCanSave(true);
			setResults({
				estimatedMonthlyTotal,
				tweets
			});
			setCachedPreview(feedRuleId, estimatedMonthlyTotal, tweets);
		} catch (e) {
			setResults({
				errorMessage: e.message
			});
			clearCachedPreview(feedRuleId);
			setCanSave(false);
		} finally {
			setIsPartialLoading(false);
		}
	}, [feedRuleId, create, feedId, feedRule?.rule, feedRule?.is_enabled, showSnackbar, setIsPartialLoading, setCanSave, setResults]);

	const handleRuleChange = (e) => {
		setFeedRule((prev) => ({ ...prev, rule: e.target.value }));
		setCanSave(false);
	};

	const renderPreview = () => {
		if (isPartialLoading) {
			return (
				<Grid item>
					<LinearProgress />
				</Grid>
			);
		}

		if (isNullOrUndefined(results)) {
			return (
				<Grid item>
					Click{' '}
					<Link className="link" onClick={() => loadPreviewData()}>
						preview
					</Link>{' '}
					to view the rule results
				</Grid>
			);
		}

		if (!stringIsNullOrEmpty(results.errorMessage)) {
			return (
				<Grid item>
					<Alert severity="error">{results.errorMessage}</Alert>
				</Grid>
			);
		}

		if (arrayIsNullOrEmpty(results.tweets)) {
			return (
				<Grid item>
					<Alert severity="error">This query returned no items</Alert>
				</Grid>
			);
		}

		return (
			<>
				<Grid item>
					<PreviewDataGrid feedRule={feedRule} tweets={results.tweets} />
				</Grid>
				{!stringIsNullOrEmpty(results.timestamp) && (
					<Grid item>
						<Typography variant="subtitle" italic>
							these results are cached from {moment(results.timestamp).local().format('DD/MM/YY @ H:mma')}. They'll be deleted
							after {tweetCacheExpiryDays} days
						</Typography>
					</Grid>
				)}
			</>
		);
	};

	const renderContent = () => {
		if (!stringIsNullOrEmpty(errorMessage)) {
			return (
				<Grid item>
					<Alert severity="error">{errorMessage}</Alert>
				</Grid>
			);
		}

		return (
			<>
				<Grid item xs={12}>
					<Inline>
						<TextField
							labelAbove
							label="Rule Name"
							value={feedRule?.name}
							onChange={(e) => setFeedRule((prev) => ({ ...prev, name: e.target.value }))}
							disabled={isSaving || isPartialLoading}
						/>
					</Inline>
				</Grid>
				<Grid item xs={12}>
					<Inline>
						<TextField
							labelAbove
							label="Rule"
							value={feedRule?.rule}
							onChange={handleRuleChange}
							disabled={isSaving || isPartialLoading}
							minRows={4}
							maxRows={10}
							multiline
						/>
					</Inline>
				</Grid>
				<Grid item>
					<Divider />
				</Grid>
				<Grid item>
					<ToolbarButtons
						estimatedMonthlyTotal={results?.estimatedMonthlyTotal}
						setIsSaving={setIsSaving}
						isSaving={isSaving}
						feedRule={feedRule}
						canSave={!isSaving && !isPartialLoading && canSave}
						canPreview={!isSaving && !isPartialLoading && !stringIsNullOrEmpty(feedRule?.rule)}
						onPreview={loadPreviewData}
					/>
				</Grid>
				{renderPreview()}
				<Grid item>
					<Divider />
				</Grid>
				<Grid item>
					<Typography variant="subtitle" italic>
						last modified {stringIsNullOrEmpty(feedRule?.user_context?.name) ? '' : `by ${feedRule.user_context.name}`} at{' '}
						{moment.utc(feedRule.date_modified_utc).format('h:mma, DD/MM/YY')}
					</Typography>
				</Grid>
			</>
		);
	};

	return (
		<Content>
			<Header header={`${create ? 'Create' : 'Edit'} ${feedNamesLookup[feedId]} Rule`} />
			<Grid container>
				<Grid item>
					{isLoading ? (
						<LinearProgress />
					) : (
						<Paper>
							<Grid container>{renderContent()}</Grid>
						</Paper>
					)}
				</Grid>
			</Grid>
		</Content>
	);
};

export default Rule;
