import { getEnvironmentName } from '@truescope-web/utils/lib/constants';

/**
 * creates an impersonation
 * @param {*} getDatahubApi apilookup
 * @param {*} clientUserId target user id
 * @returns api response
 */
export const createImpersonation = async (getDatahubApi, clientUserId) => {
	const api = await getDatahubApi();
	const response = await api.put(`/client-users/v1/${clientUserId}/impersonate`);
	return response.data;
};

/**
 * deletes an impersonation
 * @param {*} getDatahubApi apilookup
 * @param {*} clientUserId target user id
 * @returns api response
 */
export const deleteImpersonation = async (getDatahubApi, clientUserId) => {
	const api = await getDatahubApi();
	const response = await api.delete(`/client-users/v1/${clientUserId}/impersonate`);
	return response.data;
};

/**
 * checks if the datahub user can impersonate client users
 * @param {*} datahubUser
 * @returns true if they can
 */
export const canImpersonateClientUser = (email) => /.+@(truescope.com|fuseworks.co.nz)$/gi.test(email || '');

/**
 * url of the app
 */
export const appUrl = getEnvironmentName() === 'prod' ? 'app.truescope.com' : 'app.dev2.truescope.cloud';
