import DataGrid, { Column, FilterRow, HeaderFilter, RemoteOperations, Scrolling } from 'devextreme-react/data-grid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Alert from '@truescope-web/react/lib/components/modal/Alert';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { useApiLookup } from '../../../../components/ApiLookupProvider';
import { createDataSource, renderPlaintextCell } from '../../../../components/Dx/DxGridConstants';
import { useDataGridHeight } from '../../../../hooks/useWindowHeight';
import { updateFeedRule } from '../Rule/api';

const RulesTab = ({ feedId, dataTriggerGuid, refreshTabs, isEnabled = true }) => {
	const [dataSource, setDataSource] = useState(null);
	const history = useHistory();
	const { showSnackbar } = useSnackbar();
	const [anchorEl, setAnchorEl] = useState(null);
	const [showDeactivate, setShowDeactivate] = useState(false);
	const [selected, setSelected] = useState(null);
	const [getDatahubApi] = useApiLookup();

	const gridHeight = useDataGridHeight(true);

	const loadDataSource = useCallback(() => {
		const ds = createDataSource({
			getDatahubApi,
			requestPath: '/feeds/v1/feed_rules',
			useWhereClauses: true,
			requestParams: {
				whereClauses: [
					{
						field_name: 'is_enabled',
						comparison_operator: '=',
						field_value: isEnabled
					},
					{
						field_name: 'feed_id',
						comparison_operator: '=',
						field_value: feedId
					}
				]
			}
		});
		setDataSource(ds);
	}, [getDatahubApi, isEnabled]);

	useEffect(() => {
		loadDataSource();
	}, [dataTriggerGuid]);

	const dataGrid = useRef(null);

	const handleCellClick = ({ data, column }) => {
		if (isNullOrUndefined(data) || column.ignoreCellClick === true) {
			return;
		}

		history.push(`/ingest/twitter/rules/${data.feed_rule_id}`);
	};

	const handleEditButtonClick = () => {
		const { feed_rule_id } = selected;
		history.push(`/ingest/twitter/rules/${feed_rule_id}`);
	};

	const handlePromptDeactivate = () => {
		handleCloseMenu();
		setShowDeactivate(true);
	};

	const handleCancelDeactivate = () => {
		setShowDeactivate(false);
		setSelected(null);
	};

	const handleAcceptActivate = async () => {
		setShowDeactivate(false);
		setSelected(null);
		try {
			dataGrid.current._instance.beginCustomLoading();
			await updateFeedRule(getDatahubApi, {
				...selected,
				is_enabled: !isEnabled
			});
			showSnackbar(`Rule has been successfully ${isEnabled ? 'deactivated' : 'activated'}`, snackbarVariants.success);
			refreshTabs();
		} catch (e) {
			console.error('failed to activate rule', e.message);
		} finally {
			dataGrid.current._instance.endCustomLoading();
		}
	};

	const handleOpenMenu = (e, rowItem) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
		setSelected(rowItem);
	};

	const handleCloseMenu = (_e) => {
		setAnchorEl(null);
	};

	const renderOptionsCell = ({ data }) => {
		return (
			<div className="wrap">
				<IconButton aria-controls="row-menu" aria-haspopup="true" onClick={(e) => handleOpenMenu(e, data)}>
					<MoreVert />
				</IconButton>
			</div>
		);
	};

	const renderMenu = () => {
		return (
			<Menu id="row-menu" anchorEl={anchorEl} keepMounted open={!isNullOrUndefined(anchorEl)} onClose={handleCloseMenu}>
				<MenuItem onClick={handleEditButtonClick}>
					<Typography>Edit</Typography>
				</MenuItem>
				{isEnabled && (
					<MenuItem onClick={handlePromptDeactivate}>
						<Typography>Make Inactive</Typography>
					</MenuItem>
				)}

				{!isEnabled && (
					<MenuItem onClick={handlePromptDeactivate}>
						<Typography>Make active</Typography>
					</MenuItem>
				)}
			</Menu>
		);
	};

	return (
		<DataGrid
			id="sources-table"
			showRowLines
			dataSource={dataSource}
			hoverStateEnabled
			showColumnLines={false}
			onCellClick={handleCellClick}
			showBorders
			ref={dataGrid}
			height={gridHeight}
			remoteOperations
		>
			<Scrolling mode="infinite" />

			<FilterRow visible applyFilter="auto" />

			<HeaderFilter visible allowSearch />

			<Column
				caption="Name"
				dataField="name"
				cssClass="cell cell--pad clickable"
				cellRender={renderPlaintextCell}
				allowHeaderFiltering={false}
			>
				<HeaderFilter allowSearch />
			</Column>

			<Column
				caption="Rule"
				dataField="rule"
				dataType="string"
				cssClass="cell cell--pad clickable"
				allowFiltering
				allowSorting
				allowSearch={false}
				cellRender={renderPlaintextCell}
				allowHeaderFiltering={false}
			>
				<HeaderFilter allowSearch />
			</Column>

			<Column
				dataField="id"
				width={60}
				cssClass="cell cell--center"
				alignment="center"
				caption=""
				cellRender={renderOptionsCell}
				allowFiltering={false}
				allowSearch={false}
				allowSorting={false}
				ignoreCellClick
			/>

			<RemoteOperations sorting filtering />

			{renderMenu()}
			<Alert
				title="Deactivate Rule"
				message={`Are you sure you want to ${isEnabled ? 'deactivate' : 'activate'} this rule?`}
				cancelLabel="No"
				acceptLabel="Yes"
				open={showDeactivate}
				handleCancel={handleCancelDeactivate}
				handleAccept={handleAcceptActivate}
			/>
		</DataGrid>
	);
};

export default RulesTab;
