import moment from 'moment';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@truescope-web/react/lib/components/form/Checkbox';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

const Row = ({ apiKey, onChange, disabled, onDelete }) => {
	const { showSnackbar } = useSnackbar();

	const handleCopyToClipboardClick = () => {
		navigator.clipboard.writeText(apiKey.api_key);
		showSnackbar('Copied to Clipboard', snackbarVariants.success);
	};

	return (
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell component="th" scope="row">
				<Inline>
					<pre>
						<code>{apiKey.api_key}</code>
					</pre>
					<ToolbarButton title="Copy to clipboard" onClick={handleCopyToClipboardClick} icon={<ContentCopyIcon />} />
				</Inline>
			</TableCell>
			<TableCell>{moment.utc(apiKey.date_created).local().format('DD/MM/YY @ h:mm:ssa')}</TableCell>
			<TableCell>
				<Checkbox
					checked={apiKey.include_body}
					onClick={() => onChange({ ...apiKey, include_body: !apiKey.include_body })}
					disabled={disabled}
				/>
			</TableCell>
			<TableCell>
				<Checkbox
					checked={apiKey.is_deleted}
					onClick={() => onChange({ ...apiKey, is_deleted: !apiKey.is_deleted })}
					disabled={disabled}
				/>
			</TableCell>
			{!isNullOrUndefined(onDelete) && (
				<TableCell>
					<ToolbarButton
						title="DANGERZONE: DON'T DELETE ME UNLESS YOU KNOW WHAT YOU'RE DOING"
						onClick={onDelete}
						disabled={disabled}
						icon={<CloseIcon />}
					/>
				</TableCell>
			)}
		</TableRow>
	);
};

export default Row;
