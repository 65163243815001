export const feedsLookup = {
	twitter: 1,
	reddit: 5,
	twitterPro: 7
};

export const feedNamesLookup = {
	[feedsLookup.twitter]: 'Twitter',
	[feedsLookup.reddit]: 'Reddit',
	[feedsLookup.twitterPro]: 'Twitter Pro'
};
