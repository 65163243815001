import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import InternalRoute from './components/InternalRoute';
import SignOut from './views/Authentication/SignOut';
import ClientUser from './views/Clients/ClientUser/ClientUser';
import ClientUsers from './views/Clients/ClientUsers';
import Workspace from './views/Clients/Workspaces/Workspace/Workspace';
import Workspaces from './views/Clients/Workspaces/Workspaces';
import MediaItem from './views/Content/MediaItem/MediaItem';
import Scope from './views/Content/Scopes/Scope';
import Scopes from './views/Content/Scopes/Scopes';
import Search from './views/Content/Search/Search';
import Dashboard from './views/Dashboards/Dashboard';
import Authors from './views/Entities/Authors/Authors';
import Companies from './views/Entities/Companies/Companies';
import Entities from './views/Entities/Entities/';
import People from './views/Entities/People/People';
import Source from './views/Entities/Sources/Source';
import Sources from './views/Entities/Sources/Sources';
import ErrorDetails from './views/Error/ErrorDetails';
import FeedRule from './views/Ingest/Feed/Rule';
import FeedRules from './views/Ingest/Feed/Rules';
import { feedsLookup } from './views/Ingest/Feed/constants';
import SocialHandlesManager from './views/SocialHandlesManager/SocialHandlesManager';

const InternalRoutes = () => {
	const { setUserStorage, isInitialized } = useUserStorageContext();
	const history = useHistory();
	useEffect(() => {
		if (isInitialized && !history.location.pathname.startsWith('/media/feeds')) {
			setUserStorage((prev) => ({ ...prev, expandedRowKeys: [] }));
		}
	}, [history.location.pathname]);
	return (
		<Switch>
			<InternalRoute path="/" exact>
				<Redirect to="/media/search" />
			</InternalRoute>
			<InternalRoute path="/media" exact>
				<Redirect to="/media/search" />
			</InternalRoute>
			<InternalRoute path="/media/search" exact component={Search} />
			<InternalRoute path="/media/items/:document_id" exact component={MediaItem} />
			<InternalRoute path="/media/feeds" exact component={Scopes} />
			<InternalRoute path="/media/feeds/create" exact component={Scope} componentProps={{ create: true }} />
			<InternalRoute path="/media/feeds/clone/:scope_id" component={Scope} componentProps={{ create: true }} />
			<InternalRoute path="/media/feeds/:scope_id" component={Scope} />
			<InternalRoute path="/clients/workspaces/:workspace_id" component={Workspace} />
			<InternalRoute path="/clients/workspaces" component={Workspaces} />
			<InternalRoute path="/clients/users/:user_id" component={ClientUser} />
			<InternalRoute path="/clients/users" component={ClientUsers} />
			<InternalRoute path="/entities/channels" exact component={Sources} />
			<InternalRoute path="/entities/channels/:source_id" component={Source} />
			<InternalRoute path="/entities/authors" exact component={Authors} />
			<InternalRoute path="/entities/people" exact component={People} />
			<InternalRoute path="/entities/companies" exact component={Companies} />
			<InternalRoute path="/entities" exact component={Entities} />
			<InternalRoute path="/dashboards/graph/:graph_id" component={Dashboard} />
			<InternalRoute path="/ingest/social-handles" component={SocialHandlesManager} />
			<InternalRoute path="/ingest/twitter/rules" exact component={FeedRules} componentProps={{ feedId: feedsLookup.twitterPro }} />
			<InternalRoute
				path="/ingest/twitter/rules/create"
				exact
				component={FeedRule}
				componentProps={{ create: true, feedId: feedsLookup.twitterPro }}
			/>
			<InternalRoute
				path="/ingest/twitter/rules/:feed_rule_id"
				exact
				component={FeedRule}
				componentProps={{ feedId: feedsLookup.twitterPro }}
			/>
			<Route path="/signed-out" exact component={SignOut} />
			<Route path="/sign-out" exact component={SignOut} componentProps={{ automatic: true }} />
			<Route path="/error" exact component={ErrorDetails} />
			<Route path="/error/:code" component={ErrorDetails} />
			<Route componentProps={{ code: '404' }} component={ErrorDetails} />
		</Switch>
	);
};
export default InternalRoutes;
